import React from 'react'
import styled from 'styled-components';
import { breakpoints } from 'theme'
import Paragraph from 'panam/typography/Paragraph'
import SmallTitle from 'panam/typography/SmallTitle'

const ProcessWrapper = styled.div`
  align-self: baseline;
  width: 100%;
  display: flex;
  flex: 1 1 auto;
  flex-direction: column;
  align-items: center;
  position: relative;
  align-self: stretch;
  &:not(:last-child) {
    // border-right: 1px solid red;
  }
  @media only screen and (min-width: ${breakpoints.minWidthTablet}px) {
    width: calc(100% / ${({divider})=>(divider/2)>2?3:2});
  padding: 0;
  }
  @media only screen and (min-width: ${breakpoints.minWidthLaptop}px) {
    width: calc(100% / ${({divider})=>divider});
  }
`;

const ProgressDivider = styled.div`
  height: 280px;
  width: 1px;
  position: absolute;
  right: 0;
  background-color: gray;
  top: calc(50% - 140px);
  display: none;
  @media only screen and (min-width: ${breakpoints.minWidthLaptop}px) {
    display: block;
  }
`;

export default function Process({title, pic, items, divider = 4, showDivider = true}) {
  return (
    <ProcessWrapper divider={divider}>
      <SmallTitle align="center" style={{minHeight: '4.2rem'}}>{title}</SmallTitle>
      <img src={pic} alt={title} style={{width:'100px'}}/>
      <Paragraph align="center">
        <ul style={{listStyle:'none', padding: 0}}>
          {items.map(it=><li>- {it}</li>)}
        </ul>
      </Paragraph>
      {showDivider && <ProgressDivider/>}
    </ProcessWrapper>
  );
}
