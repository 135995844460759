import React from 'react'
import NavBar from 'navigation/components/NavBar'
import Logo from 'navigation/components/Logo'
import Menu from 'navigation/components/Menu'
import NavButton from 'navigation/components/NavButton'
import Container from './Container'
import HideOnMobile from 'common/components/HideOnMobile'
import ShowOnMobile from 'common/components/ShowOnMobile'
import { ViewLink } from 'common/components/Card'
import Process from 'common/Process'
import styled from 'styled-components';
import { breakpoints, colors } from 'theme'
import TopLevelImg from './imgs/Group.png';
import Persona from './imgs/Spotify _ Persona.png';
import StoryBoard from './imgs/Spotify _ Story Board.jpg';
import UserFlow1 from './imgs/Spotify _ User flow_1.jpg';
import UserFlow2 from './imgs/Spotify _ User flow_2.jpg';
import backgr from './imgs/cover.png';
import Wireframes from './imgs/Spotify___Wireframes.jpg';
import Designs from './imgs/UI Designs.jpg';
import ResearchPic from './imgs/research.png';
import ProcessPic from './imgs/process.png';
import ConversationPic from './imgs/conversation.png';
import WireframePic from './imgs/wireframe.png';
import Title from 'spotify/typography/Title'
import Paragraph from 'spotify/typography/Paragraph'
import Quote from 'spotify/typography/Quote'
import IdeaPic from 'spotify/imgs/idea.png'
import Header from 'panam/typography/Header'

const CircleDivider = styled.div`
  width: 3px;
  height: 3px;
  margin-right: 5px;
  margin-left: 5px;
  border-radius: 50px;
  background-color: ${colors.textPrimary};
  display: none;
  @media only screen and (min-width: 321px) {
    display: block;
  }
`;
const Divider = styled.div`
  width: 70%;
  height: 1px;
  margin: 3rem 0 6rem 0;
  background-color: #979797;
`;
const TopImage = styled.img`
  width: 95%;
  max-width: 500px;
`;

const Footer = styled.footer`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 6rem;
  color: ${colors.textPrimary} !important;
`;

const LinkGroup = styled.footer`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: ${colors.textPrimary} !important;
  @media only screen and (min-width: 321px) {
    flex-direction: row;
  }
`;

const CopyRights = styled.div`
  color: ${colors.textSecondary}
`;

const Section2 = styled.div`
  background-color: ${({color})=>color || 'white'};
  background: url(${backgr});
  @media only screen and (min-width: 400px) {
    background-repeat: no-repeat;
    background-size: cover;
    background-position-y: 30%;
  }
  padding: 4rem 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  ${({gradient=''})=>gradient && 'background: radial-gradient(circle at bottom,#aad2e2 10%,#3066db 70%);'};
`;

const Section = styled.div`
  background-color: ${({color})=>color || 'white'};
  padding-top: 4rem;
  padding-bottom: 4rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  ${({gradient=''})=>gradient && 'background: radial-gradient(circle at bottom,#aad2e2 10%,#3066db 70%);'};
`;

function SpotifyCaseStudy() {
  React.useEffect(()=>{
    window.scrollTo(0,0);
  }, [])
  return (
    <div className="App">
      <NavBar>
        <Container>
          <div><Logo /></div>
          <HideOnMobile>
            <NavButton href="#work">Work</NavButton>
            <NavButton href="#about">About</NavButton>
            <NavButton href="#contacts">Contacts</NavButton>
          </HideOnMobile>
          <ShowOnMobile>
            <Menu />
          </ShowOnMobile>
        </Container>
      </NavBar>
      <Section2>
        <Header color="white" align="center">Adding feature to Spotify<br/>Case Study</Header>
        <TopImage src={TopLevelImg} alt="Adding feature to Spotify" />
      </Section2>
      <Section color="#000000">
        <Container direction="column">
          <Title color="white" align="center">Making Spotify social</Title>
          <Paragraph color="white" align="center">This project was done as a part of Design Lab studying program. There’s a special connection between humans and music. Spotify, leader on streaming music, wants to make a move into helping that connection further.</Paragraph>
          <Title color="white" align="center">Goals</Title>
          <Paragraph color="white" align="center">Determining and designing of a new social feature that embeds within the current Spotify platform in mobile device.</Paragraph>
          <Title color="white" align="center">Users</Title>
          <Paragraph color="white" align="center">Music lovers all over the world. People that are interested in not only finding and listening to the music but also making connections through this.</Paragraph>
        </Container>
      </Section>
      <Section>
        <Container direction="row" style={{flexWrap: 'wrap', marginBottom: '4rem'}}>
          <Process divider={5} title="research" pic={ResearchPic} items={['interviews ( 2 rounds)','persona development']} />
          <Process divider={5} title="ideation" pic={IdeaPic} items={['problem statement','story boarding']} />
          <Process divider={5} title="interaction design" pic={ProcessPic} items={['user flow','wireframes']} />
          <Process divider={5} title="user interface design" pic={WireframePic} items={['UI design']} />
          <Process divider={5} showDivider={false} title="iteration and implementation" pic={ConversationPic} items={['usability testing','iteration']} />
        </Container>
        <Container direction="column">
          <Title align="center">Project summary</Title>
          <Paragraph align="center">Spotify already has some core capabilities for being social, like following artists or friends, and a basic feed of activity. However, there’s much more that can be done. Any new social capability should be in favor of human and  emotional connection, primarily centered around music.</Paragraph>
          <Paragraph align="center">With all of that said, first huge step was done through the research. A lot of secondary research was done to dig deep into the needs and pains of users that could be solved through a social feature.</Paragraph>
          <Divider />
          <Title align="center">Research</Title>
          <Paragraph align="center">Research started with first round of interviews. Interviewees were asked whether they listen to the music, what device do they use, what app. Also, do they share music or do they do any social interactions using music.</Paragraph>
          <Paragraph align="center">4 interviews were taken. It was quickly discovered that users prefer not to share music as they feel this is useless. They stated that sharing music is “too personal”, “music needs to be explained”. At the same time, users stated that they like to follow people, save their playlists and they would also like to stay updated with these people’s music.</Paragraph>
          <Paragraph align="center">Second round of interview was conducted after finding some pain points from the first round of interviews. This time, users were asked about finding people they could follow. How they determine they could rely on this person’s tastes.</Paragraph>
        </Container>
      </Section>
      <Section color="#1DB954">
        <Container direction="column">
          <Quote color="white" align="center"  style={{marginBottom: 20}}>“ I would follow someone with very similar tastes. I hate new music, I don’t have time finding it, so I could use a person I can rely on.”</Quote>
          <Quote color="white" align="center">“ I add 2-3 songs a year, maybe. Sometimes I can follow recommendations... but not much.”</Quote>
        </Container>
      </Section>
      <Section>
        <Container direction="column">
          <Paragraph align="center">Using the Data received from interview, persona that represents the main user group was created. Natalie is a freshman at Berkeley college of music.  She is a music fan as all of her friends and classmates. She uses Spotify not only for listening to the music, but to discover new tracks, playlists, genres and people. She also likes to share her music with others.</Paragraph>
          <img src={Persona} alt="Persona" style={{width:'100%', marginBottom: 40, marginTop: 10}}/>
          <Title align="center">Research findings are:</Title>
          <Paragraph align="center">
            <ul style={{listStyle:'none', padding: 0, margin: 0}}>
              <li>- Users generally don’t like to share music</li>
              <li>- Users stated that they don’t have time to listen to hundreds of tracks to find the one they would like</li>
              <li>- Users tend to follow other users if they feel they can trust their music taste</li>
              <li>- Users are interested in people and playlists with some similarity to their choices</li>
            </ul>
          </Paragraph>
          <Divider/>
          <Title align="center">Ideation</Title>
          <Paragraph align="center">As a result of research process, main pain points were determined. Also, main problem was stated: How might we help Users to choose who is the best match. To solve that problem let's move further to Story boarding.</Paragraph>
        </Container>
      </Section>
      <Section color="#1DB954">
        <Container direction="column">
          <Quote color="white" align="center">How might we help Users to choose who is the best match</Quote>
        </Container>
      </Section>
      <Section>
        <Container direction="column">
          <Paragraph align="center">Using Crazy 8th technique I was able to get rapid ideas to solve my main problem, and dig deeper and deeper finding new ways to design an interface that would make things easy for User.</Paragraph>
          <img src={StoryBoard} alt="Story Board" style={{width:'100%', marginBottom: 40, marginTop: 10}}/>
          <Divider/>
          <Title align="center">Interaction Design</Title>
          <Paragraph align="center">User Flow was created for our persona Natalie. At fist, that was the User flow:</Paragraph>
          <img src={UserFlow1} alt="User Flow part 1" style={{width:'100%', marginBottom: 40, marginTop: 10}}/>
          <Paragraph align="center">My Usability Testing showed the confusion of Users going through that flow. Filter page appeared too fast, people expected to see their previous choices and people they already follow. That is why second version of the User flow was created. This version showed better results and eased Usability Testing process.</Paragraph>
          <img src={UserFlow2} alt="User Flow part 2" style={{width:'100%', marginBottom: 40, marginTop: 10}}/>
          <Paragraph align="center">The next step was to create Wireframes. Balsamiq tool was used to do that. Wireframes that you see here were created before Usability Testing. This was original User Flow - jumping from Homepage to the Filter page with one tap. It turned out to be point of confusion for my Users.</Paragraph>
          <img src={Wireframes} alt="Wireframes" style={{width:'100%', marginBottom: 40, marginTop: 10}}/>
          <Paragraph align="center">After the Wireframes set, it was time to start working on UI designs.</Paragraph>
          <Divider/>
          <Title align="center">User Interface Design</Title>
          <Paragraph align="center">Two sets of UI designs were created. The first one was done after Wireframes and before conducting first User Testing. Starting with Homepage, user was enabled to go through Filter page and find a person to follow. Second set of UI Designs allow users to see who do they follow first, to get them an understanding of using the social part of Spotify. Here you can see the second set of designed screens.</Paragraph>
          <img src={Designs} alt="Designs" style={{width:'100%', marginBottom: 40, marginTop: 10, maxWidth: 800}}/>
          <Divider/>
          <Title align="center">Iteration and Implementation</Title>
          <Paragraph align="center">There was one round of Usability Testing, that was conducted with designed screens. There were a lot of insights and people’s expectations from this one.  First of all, User flow had to be changed. Users had to find and add a person to follow, and there was a lot of confusion during that process. Some of my users coudn’t find the main Icon that is leading to the feature, but most of them were expecting to see people they already follow as the part of the process of a search for a new one.</Paragraph>
        </Container>
      </Section>
      <Section color="#1DB954">
        <Container direction="column">
          <Quote color="white" align="center" style={{marginBottom: 20}}>“ I don’t understand what is this filter page about, am I already trying to find a person I could follow?”</Quote>
          <Quote color="white" align="center">“ Am I following someone already?… Or I can only add new people. But where can I see my previous choices?”</Quote>
        </Container>
      </Section>
      <Section>
        <Container direction="column">
          <Divider/>
          <Title align="center">Reflection</Title>
          <Paragraph align="center">This project was a bit of a struggle from the very beginning. Research part was a difficult one because of not knowing where to start. After the first round of interviews some insights were gained and it felt more comfortable digging deeper. Crazy 8th sketches were challenging at first, but once started - it was a moment of magic! Lots of ideas came into my mind and continued coming even days after.</Paragraph>
          <Paragraph align="center">That project gave me better understanding of research process. It taught me to keep going even if you don’t catch the problem, or don’t see an obvious pain point or unmet need of a user. This brought me through uncomfortable interviews where I adapted and just kept talking to the person no matter what.</Paragraph>
          <Paragraph align="center">I hope to have more challenging projects like this in the nearest future as overcoming struggles is the only way to succeed.</Paragraph>
        </Container>
      </Section>
      <Footer id="contacts">
        <LinkGroup>
          <ViewLink href="mailto:anastasiia.ezhikova@gmail.com?subject=Let's Connect!">anastasiia.ezhikova@gmail.com</ViewLink>
          <CircleDivider />
          <ViewLink rel="noopener noreferrer" target="_blank" href="https://www.linkedin.com/in/anastasiia-ezhikova-04538714a">LinkedIn</ViewLink>
        </LinkGroup>
        <CopyRights>© 2019 Anastasiia Ezhikova</CopyRights>
      </Footer>
    </div>
  );
}

export default SpotifyCaseStudy;
