import React from 'react';
import styled from 'styled-components'
import { Link } from 'react-router-dom';
import HideOnMobile from 'common/components/HideOnMobile'
import {colors} from 'theme'

const Brand = styled(Link)`
  color: ${colors.textPrimary};
  font-size: 2.4rem;
  line-height: 3.3rem;
  font-weight: 1000;
  text-align: center;
  letter-spacing: 0.125rem;
  text-transform: uppercase;
  margin-right: 2rem;
`;

const Logo = () =>(<Brand to="/"><HideOnMobile>Stacey</HideOnMobile> Ezhikova</Brand>)

export default Logo;