import React from 'react';
import styled from 'styled-components'
import {colors} from 'theme'

export default styled.h2`
  color: ${({color})=>colors[color] || colors.textPrimary}
  margin: 2rem 0 0;
  font-size: 3.5rem;
  font-weight: 500;
  line-height: 1;
  letter-spacing: -0.01562em;
  ${({shadow})=>shadow ? 'text-shadow: 2px 2px #000;' : ''}
  ${({align})=>align?`text-align: ${align};`:''}
`;
