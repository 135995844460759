import React from 'react';
import styled from 'styled-components'
import {colors} from 'theme'

export default styled.p`
  font-family: 'Barlow', sans-serif;
  color: ${({color})=>colors[color] || colors.textPrimary}
  margin: ${({disableGutter})=>disableGutter?'0':'0 0 3rem 0'};
  font-size: 2rem;
  line-height: 2.5rem;
  ${({shadow})=>shadow ? 'text-shadow: 2px 2px #000;' : ''}
  ${({align})=>align?`text-align: ${align};`:''}
`;
