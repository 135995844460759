import React from 'react';
import styled from 'styled-components';

const Animation = styled.div`
  opacity: 0;
  transform: translateX(0px) translateY(40px) translateZ(0px);
  transition: opacity ${props => props.duration}ms ease 0s,transform ${props => props.duration}ms ease 0s;
  &.launch {
    opacity: 1;
    transform: translateX(0px) translateY(00px) translateZ(0px);
  }
`;

export default ({children,duration=1300}) => {
  const [classNames, setClassNames] = React.useState('')
  React.useEffect(()=>{
    setClassNames('launch')
  }, [])
  console.log(classNames)
  return <Animation duration={duration} className={classNames}>{children}</Animation>
}