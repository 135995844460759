import styled from 'styled-components'
import { breakpoints } from 'theme'

export default styled.header`
  display: flex;
  flex: 1 1 auto;
  justify-content: space-between;
  height: 6rem;
  width: 100%;
  align-items: center;
  position: relative;
  z-index: 800;
  box-shadow: 0px 2px 4px -1px rgba(0,0,0,0.2), 0px 4px 5px 0px rgba(0,0,0,0.14), 0px 1px 10px 0px rgba(0,0,0,0.12);
  @media only screen and (min-width: ${breakpoints.minWidthTablet}px) {
    height: 10rem;
  }
`;