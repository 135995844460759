export const colors = {
  textPrimary: '#000',
  textSecondary: 'gray',
  white: 'white',
  primary: '#367d91',
  secondary: 'blue'
}

export const breakpoints = {
  minWidthTablet: 768,
  minWidthLaptop: 1024,
  minWidthDesktop: 1280,
  minWidthDesktopXl: 1920
}