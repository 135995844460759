import React from 'react'
import NavBar from 'navigation/components/NavBar'
import Logo from 'navigation/components/Logo'
import Menu from 'navigation/components/Menu'
import NavButton from 'navigation/components/NavButton'
import Container from 'common/components/Container'
import HideOnMobile from 'common/components/HideOnMobile'
import ShowOnMobile from 'common/components/ShowOnMobile'
import Section from 'common/components/Section'
import Card, { ViewLink } from 'common/components/Card'
import Animated from 'common/components/Animated'
import {Header,TagLine,SubHeader,Title,Paragraph} from 'typography'
import styled from 'styled-components';
import background from 'img/main_background.png';
import AvatarImg from 'img/avatar.jpeg';
import ImmiFriends from 'img/ImmiFriends___Project_cover.jpg';
import Spotify from 'img/Spotify___Project_cover.jpg';
import PanAm from 'img/PanAm_Project_cover.jpg';
import { breakpoints, colors } from 'theme'

export const BottomLink = styled.a`
  color: ${colors.primary};
  font-size: 1.6rem;
  line-height: 2.3rem;
  letter-spacing: 0.05rem;
  :hover {
    text-decoration: underline;
  }
`;

const Image = styled(Section)`
  max-height: 100vh;
  background: url(${background});
  background-repeat: no-repeat;
  background-size: cover;
  height: 50rem;
  padding: 0;
`;
const AvatarWrapper = styled.a`
  padding: 35%;
  position: relative;
  margin-bottom: 2rem
  @media only screen and (min-width: ${breakpoints.minWidthTablet}px) {
    padding: 20%;
    max-width: 1px;
  }
`
const avatarReducer = '20%';
const Avatar = styled.div`
  background: url(${AvatarImg});
  background-repeat: no-repeat;
  background-size: cover;
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  @media only screen and (min-width: ${breakpoints.minWidthTablet}px) {
    top: ${avatarReducer};
    bottom: ${avatarReducer};
    right: ${avatarReducer};
    left: ${avatarReducer};
  }
  border-radius: 50%;
`;
const CircleDivider = styled.div`
  width: 3px;
  height: 3px;
  margin-right: 5px;
  margin-left: 5px;
  border-radius: 50px;
  background-color: ${colors.textPrimary};
  display: none;
  @media only screen and (min-width: 321px) {
    display: block;
  }
`;

const Footer = styled.footer`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 6rem;
  color: ${colors.textPrimary} !important;
`;

const LinkGroup = styled.footer`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: ${colors.textPrimary} !important;
  @media only screen and (min-width: 321px) {
    flex-direction: row;
  }
`;

const CopyRights = styled.div`
  color: ${colors.textSecondary}
`;

function App() {
  return (
    <div className="App">
      <NavBar>
        <Container>
          <div><Logo /></div>
          <HideOnMobile>
            <NavButton href="#work">Work</NavButton>
            <NavButton href="#about">About</NavButton>
            <NavButton href="#contacts">Contacts</NavButton>
          </HideOnMobile>
          <ShowOnMobile>
            <Menu />
          </ShowOnMobile>
        </Container>
      </NavBar>
      <div>
        <Image>
          <div>
            <Animated>
              <Header align="center">
                UX/UI Designer
              </Header>
            </Animated>
            <Animated>
              <TagLine align="center">
                EMPATHY. COMMITMENT. RESULT
              </TagLine>
            </Animated>
          </div>
        </Image>
      </div>
      <Section title="Work">
        <Card img={ImmiFriends} title="ImmiFriends - easy way to find friends in a new country" link="/immi">
          ImmiFriends app helps immigrants to become a part of their native community, find friends, events and groups. Using research, creating new account flow was designed.
        </Card>
        <Card img={Spotify} title="Adding feature to Spotify" link="/spotify">
          Through interviews I found out that Users struggle to find people with similar music tastes. Using research results I created a feature to help Users find a perfect match for their needs.
        </Card>
        <Card img={PanAm} title="Re-branding of Pan Am Airlines" link="/pan_am">
          Pan Am airlines want to make a very strong comeback. For that they need re-branding and a next-level User experience for their core digital flow involving the booking process.
        </Card>
      </Section>
      <Section id="about">
        <Container direction="column">
          <AvatarWrapper>
            <Avatar/>
          </AvatarWrapper>
          <div style={{padding: '0 2rem'}}>
            <SubHeader style={{margin:'0 0 2rem'}}>About Me</SubHeader>
            <Title><img src="https://uploads-ssl.webflow.com/5996a65d7488150001d1bdce/59bb868ba5423500011ec6e4_location.png" width="15" height="15" alt="" /> Boston, MA</Title>
            <Paragraph>I am UX/UI designer with an engineering background. I believe that design can make a huge difference for millions of people. I value simplicity and efficiency, committing to empathy, quality and user-centered design. I am always willing to learn and develop professionally.</Paragraph>
            <Paragraph>Outside of design, my biggest passions are travelling, reading and spending quality time with my family and friends.</Paragraph>
            <Paragraph>Fun fact: My last name is translated from Russian as "little hedgehog".</Paragraph>
            <Paragraph>Feel free to get in touch by checking out my&nbsp;
              <BottomLink rel="noopener noreferrer" target="_blank" href="https://www.linkedin.com/in/anastasiia-ezhikova-04538714a">LinkedIn</BottomLink>
              &nbsp;or drop a line at&nbsp;<BottomLink href="mailto:anastasiia.ezhikova@gmail.com?subject=Let's Connect!">anastasiia.ezhikova@gmail.com</BottomLink>
            </Paragraph>
          </div>
        </Container>
      </Section>
      <Footer id="contacts">
        <LinkGroup>
          <BottomLink href="mailto:anastasiia.ezhikova@gmail.com?subject=Let's Connect!">anastasiia.ezhikova@gmail.com</BottomLink>
          <CircleDivider />
          <BottomLink rel="noopener noreferrer" target="_blank" href="https://www.linkedin.com/in/anastasiia-ezhikova-04538714a">LinkedIn</BottomLink>
        </LinkGroup>
        <CopyRights>© 2019 Anastasiia Ezhikova</CopyRights>
      </Footer>
    </div>
  );
}

export default App;
