import React from 'react';
import styled from 'styled-components'
import {colors} from 'theme'

export default styled.p`
  font-family: 'Montserrat', sans-serif;
  font-size: 600;
  font-style: italic;
  color: ${({color})=>colors[color] || colors.textPrimary}
  margin: 0;
  font-size: 2.6rem;
  line-height: 3.2rem;
  ${({shadow})=>shadow ? 'text-shadow: 2px 2px #000;' : ''}
  ${({align})=>align?`text-align: ${align};`:''}
`;
