import React from 'react'
import NavBar from 'navigation/components/NavBar'
import Logo from 'navigation/components/Logo'
import Menu from 'navigation/components/Menu'
import NavButton from 'navigation/components/NavButton'
import Container from './Container'
import HideOnMobile from 'common/components/HideOnMobile'
import ShowOnMobile from 'common/components/ShowOnMobile'
import { ViewLink } from 'common/components/Card'
import Process from 'common/Process'
import styled from 'styled-components';
import { breakpoints, colors } from 'theme'
import TopLevelImg from './imgs/Apple iPhone XR Blue Copy.png';
import Persona from './imgs/Persona for Case sudy.png';
import EmpathyMap from './imgs/Empathy map for Case study.png';
import UserFlow from './imgs/User Flow_Pan Am_V3@2x.jpg';
import UiKit from './imgs/UI Kit.jpg';
import Wireframes1 from './imgs/Wireframes_1.jpg';
import Wireframes2 from './imgs/Wireframes 2.jpg';
import Designs1 from './imgs/UI Designs 1.jpg';
import Designs2 from './imgs/Ui Designs 2.jpg';
import ResearchPic from './imgs/research.png';
import ProcessPic from './imgs/process.png';
import ConversationPic from './imgs/conversation.png';
import WireframePic from './imgs/wireframe.png';
import backgr from './imgs/PanAm_background.jpg';
import Header from 'panam/typography/Header'
import Title from 'panam/typography/Title'
import Paragraph from 'panam/typography/Paragraph'
import Quote from 'panam/typography/Quote'

const CircleDivider = styled.div`
  width: 3px;
  height: 3px;
  margin-right: 5px;
  margin-left: 5px;
  border-radius: 50px;
  background-color: ${colors.textPrimary};
  display: none;
  @media only screen and (min-width: 321px) {
    display: block;
  }
`;
const Divider = styled.div`
  width: 70%;
  height: 1px;
  margin: 3rem 0 6rem 0;
  background-color: #979797;
`;
const TopImage = styled.img`
  width: 95%;
  max-width: 500px;
`;

const Footer = styled.footer`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 6rem;
  color: ${colors.textPrimary} !important;
`;

const LinkGroup = styled.footer`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: ${colors.textPrimary} !important;
  @media only screen and (min-width: 321px) {
    flex-direction: row;
  }
`;

const CopyRights = styled.div`
  color: ${colors.textSecondary}
`;

const Section2 = styled.div`
  background-color: ${({color})=>color || 'white'};
  @media only screen and (min-width: 400px) {
    background: url(${backgr});
    background-repeat: no-repeat;
    background-size: cover;
    background-position-y: 30%;
  }
  padding: 4rem 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  ${({gradient=''})=>gradient && 'background: radial-gradient(circle at bottom,#aad2e2 10%,#3066db 70%);'};
`;

const Section = styled.div`
  background-color: ${({color})=>color || 'white'};
  padding-top: 4rem;
  padding-bottom: 4rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  ${({gradient=''})=>gradient && 'background: radial-gradient(circle at bottom,#aad2e2 10%,#3066db 70%);'};
`;

function App() {
  React.useEffect(()=>{
    window.scrollTo(0,0);
  }, [])
  return (
    <div className="App">
      <NavBar>
        <Container>
          <div><Logo /></div>
          <HideOnMobile>
            <NavButton href="#work">Work</NavButton>
            <NavButton href="#about">About</NavButton>
            <NavButton href="#contacts">Contacts</NavButton>
          </HideOnMobile>
          <ShowOnMobile>
            <Menu />
          </ShowOnMobile>
        </Container>
      </NavBar>
      <Section2 color="#3066DB" gradient>
        <Header color="white" align="center">Pan American Airways<br/>Case Study</Header>
        <TopImage src={TopLevelImg} alt="PanAm on mobile" />
      </Section2>
      <Section color="#D9DDE5">
        <Container direction="column">
          <Title align="center">Pan American Airways</Title>
          <Paragraph align="center">This project was done as a part of Design Lab studying program. Pan Am airlines was the largest international air carrier in the United States from 1927 until its collapse on December 4, 1991.</Paragraph>
          <Title align="center">Goals</Title>
          <Paragraph align="center">Pan Am makes a very strong comeback, and for that they need a next-level user experience on mobile devices specifically.</Paragraph>
          <Title align="center">Users</Title>
          <Paragraph align="center">Air travellers in search of an elevated, thoughtful experience, similar to the legendary service of the Four Seasons.</Paragraph>
        </Container>
      </Section>
      <Section>
        <Container direction="row" style={{flexWrap: 'wrap'}}>
          <Process title="research" pic={ResearchPic} items={['interviews','market and competitor research','persona and empathy map development']} />
          <Process title="interaction design" pic={ProcessPic} items={['user flow','wireframes']} />
          <Process title="user interface design" pic={WireframePic} items={['UI design','UI kit']} />
          <Process title="iteration and implementation" pic={ConversationPic} items={['usability testing','iteration']}  showDivider={false} />
        </Container>
        <Container direction="column">
          <Title align="center">Project summary</Title>
          <Paragraph align="center">Pan Am is making a big comeback and company has to work within an existing, iconic brand. At the same time, designer is expected to translate and refresh the brand too. For now, customer wants to see mobile version of e-commerce website, specifically booking flow.</Paragraph>
          <Paragraph align="center">Pan Am focuses on the exceptional customer experience and sees the product as elegant, playful, hip, aspirational.</Paragraph>
          <Paragraph align="center">For now, Pan Am wants to see mobile version of the e-commerce website. Specifically the flow of booking airtickets.</Paragraph>
          <Divider />
          <Title align="center">Research</Title>
          <Paragraph align="center">Research begun by market research and competitor analysis to dig deeper into market options and features that ease user experience these days.</Paragraph>
          <Paragraph align="center">4 interviews were taken. It was quickly discovered that customers struggle with finding information about  luggage and meals (whether included or not) also have difficulties understanding choosing seats option. One of the unmet need is lack of clear and clean way to show information about flight, its length, duration and number of stopovers.</Paragraph>
          <Paragraph align="center">Some of the interviewees stated that seeing banners and discounts everywhere was not only overwhelming, but also leads to lack of concentration during booking process. On the other hand, a chart with different options as a chance to save money is valuable, all of the interviewees stated that they would check one.</Paragraph>
        </Container>
      </Section>
      <Section color="#3066DB">
        <Container direction="column">
          <Quote color="white" align="center" style={{marginBottom: 20}}>“Luggage information is always a drama. Difficult to find and changes from flight to flight”</Quote>
          <Quote color="white" align="center">“Transparency is key. I want to see what am I paying for, including taxes and fees.”</Quote>
        </Container>
      </Section>
      <Section>
        <Container direction="column">
          <Paragraph align="center">Using the Data received from interview, persona was created, representing the main user group. Jeremy is a Product Manager in IT company.  Jeremy travels a lot. He books tickets for all of his trips, family or work related. He wants booking process to be simple, clean and clear so that he didn’t feel frustrated searching for any piece of info he needs or trying to close discount banners to continue booking.</Paragraph>
          <img src={Persona} alt="Persona" style={{width:'100%', marginBottom: 40, marginTop: 10}}/>
          <Paragraph align="center">It was decided to dig deeper into our Persona so that we could understand his pains and gains, his lifestyle, his daily and life routine. Empathy map was created to not only feel all the user’s needs but also to have a sense of what would be an excellent experience.</Paragraph>
          <img src={EmpathyMap} alt="Empathy map" style={{width:'100%', marginBottom: 40, marginTop: 10}}/>
          <Title align="center">Research findings are:</Title>
          <Paragraph align="center">
            <ul style={{listStyle:'none', padding: 0, margin: 0}}>
              <li>- Lack of luggage, meals and seats selection information is frustrating</li>
              <li>- Summary of choices before paying and confirmation of choices after paying is an unmet need</li>
              <li>- Inability to choose and add services is an unmet need</li>
              <li>- Overwhelming amount of sales and banners is annoying</li>
              <li>- Transparent interface that is not overloaded with information is an unmet need</li>
              <li>- It is convenient for users to use comparison charts</li>
              <li>- Clear and full information about the flight is an unmet need</li>
            </ul>
          </Paragraph>
          <Divider/>
          <Title align="center">Interaction Design</Title>
          <Paragraph align="center">User Flow was created for our persona Jeremy. It shows how Jeremy would follow the booking process. Having first to select departure and return flight, then moving to seats selection, customizing flight and finally paying.</Paragraph>
          <a href={UserFlow}><img src={UserFlow} alt="User flow" style={{width:'100%', marginBottom: 40, marginTop: 10}}/></a>
          <Paragraph align="center">The next step was to create Wireframes. First wireframes were kept as simple as possible, not visualizing a lot, just sketching the ideas and meeting pain points that we received from the research stage. When these Wireframes were done, they were pushed a bit further to add cleanliness and stylish look.  You can see both examples of my first and second Wireframes interactions.</Paragraph>
          <a href={Wireframes1}><img src={Wireframes1} alt="Wireframes" style={{width:'100%', maxWidth: 700, marginBottom: 40, marginTop: 10}}/></a>
          <a href={Wireframes2}><img src={Wireframes2} alt="Wireframes" style={{width:'100%', maxWidth: 700, marginBottom: 40, marginTop: 10}}/></a>
          <Paragraph align="center">When the Wireframes set was done, the first round of Usability Testing was conducted. Through that some valuable feedback was received and it was time to rethought some of my ideas. Next step was to move on to UI design.</Paragraph>
          <Divider/>
          <Title align="center">User Interface Design</Title>
          <Paragraph align="center">UI Kit was created to show branding ideas and details. According to the customer brief, new brand ideas were presented. Logo and colors are still Pan Am style but have modern and fresh look. Typeface was chosen to make the brand look stylish, light and aspirational, also still be easily readable. There is also detailed view of the buttons, dropdowns and other elements to give a customer clear understanding of how the design will look like.</Paragraph>
          <a href={UiKit}><img src={UiKit} alt="User flow" style={{width:'100%', maxWidth: 900, marginBottom: 40, marginTop: 10}}/></a>
          <Paragraph align="center">Finally, UI screens were designed. All of the designs were done in Sketch. Keeping users pain points and unmet needs in mind, these screens were created:</Paragraph>
          <a href={Designs1}><img src={Designs1} alt="Designs" style={{width:'100%', maxWidth: 700, marginBottom: 40, marginTop: 10}}/></a>
          <a href={Designs2}><img src={Designs2} alt="Designs" style={{width:'100%', maxWidth: 700, marginBottom: 40, marginTop: 10}}/></a>
          <Divider/>
          <Title align="center">Iteration and Implementation</Title>
          <Paragraph align="center">There were two rounds of Usability Testing, first one was conducted with Low Fidelity Wireframes. I’ve got a lot of insights and people’s expectations from this one.  Second round was done after final UI design and process was smooth and quick. Both of the Usability Testings were done using InVision App.</Paragraph>
        </Container>
      </Section>
      <Section color="#4A4A4A">
        <Container direction="column">
          <Quote color="white" align="center" style={{marginBottom: 20}}>“I would like to have an option to change my decision on every step of the process”</Quote>
          <Quote color="white" align="center">“I would like to see total price once again before paying as I tend to forget things while booking”</Quote>
        </Container>
      </Section>
      <Section>
        <Container direction="column">
          <Paragraph align="center">There were few minor issues with a first round of Usability Testing. Users had difficulties understanding whether they choose departing flight, returning one, or both. Icons were added considering this problem. Also, users were confused for not having explanation of confirmation (were confirmation was sent). Three out of three users stated that they would like to have a change button for as many steps as possible.</Paragraph>
          <Divider/>
          <Title align="center">Reflection</Title>
          <Paragraph align="center">This project was not easy to design. A lot of time was spend thinking about branding and sketching logos. The most difficult part was keeping things clean yet have enough information for User to understand how the things work and what is expected to be next. Research part was tricky as people wanted to talk about travelling, planes, but not exactly about booking process, so my job was to smoothly turn the conversation into needed direction.</Paragraph>
          <Paragraph align="center">I was extremely excited to run Usability Testing as it brought some valuable feedback. Second round of UT was a complete pleasure as my users were fast and did not seem to have problems or pain points. Logo re-branding was also the part where a lot of my skills were used. This process kept going until a perfect idea was found.</Paragraph>
          <Paragraph align="center">Working on that project was a valuable experience. I’ve continued to improve my skills and am now ready to move on to new exciting projects ahead.</Paragraph>
        </Container>
      </Section>
      <Footer id="contacts">
        <LinkGroup>
          <ViewLink href="mailto:anastasiia.ezhikova@gmail.com?subject=Let's Connect!">anastasiia.ezhikova@gmail.com</ViewLink>
          <CircleDivider />
          <ViewLink rel="noopener noreferrer" target="_blank" href="https://www.linkedin.com/in/anastasiia-ezhikova-04538714a">LinkedIn</ViewLink>
        </LinkGroup>
        <CopyRights>© 2019 Anastasiia Ezhikova</CopyRights>
      </Footer>
    </div>
  );
}

export default App;
