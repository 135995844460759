import React from 'react'
import styled from 'styled-components';
import SubHeader from 'typography/SubHeader'

const Section = styled.div`
  padding-top: 3rem;
  z-index: 100;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
`;

export default ({children, title='', id, ...rest}) => {
  return (
    <Section id={id || title.toLowerCase()} {...rest}>
      {title && <SubHeader>{title}</SubHeader>}
      {children}
    </Section>
  );
}