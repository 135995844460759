import React from 'react'
import NavBar from 'navigation/components/NavBar'
import Logo from 'navigation/components/Logo'
import Menu from 'navigation/components/Menu'
import NavButton from 'navigation/components/NavButton'
import Container from './Container'
import HideOnMobile from 'common/components/HideOnMobile'
import ShowOnMobile from 'common/components/ShowOnMobile'
import { ViewLink } from 'common/components/Card'
import styled from 'styled-components';
import { colors } from 'theme'
import TopLevelImg from './imgs/Group (1).png';
import ResearchRampUp from './imgs/Research Ramp-Up.png';
import Interview1 from './imgs/Pic interview 4.jpg';
import Interview2 from './imgs/Pic interview key words.jpg';
import Persona from './imgs/Persona Development.png';
import ImmiFriendsEmpathyMap from './imgs/ImmiFriends _ Empathy Map.png';
import Ideation from './imgs/ImmiFriends _ Ideation.JPG';
import UserFlow from './imgs/ImmiFriends _ User flow.jpg';
import Wireframes from './imgs/ImmiFriends___Wireframes_photo.jpg';
import Branding from './imgs/ImmiFriends _ Branding.png';
import Logo1 from './imgs/Photo logo1.JPG';
import Logo2 from './imgs/Photo logo2.JPG';
import UIScreens from './imgs/UI Screens all in 1.jpg';
import Header from 'immifriends/typography/Header'
import Title from 'immifriends/typography/Title'
import Paragraph from 'immifriends/typography/Paragraph'
import Quote from 'immifriends/typography/Quote';
import ResearchPic from './imgs/research.png';
import ProcessPic from './imgs/process.png';
import backgr from './imgs/ImmiFriends___background.jpg';
import ConversationPic from './imgs/conversation.png';
import WireframePic from './imgs/wireframe.png';
import IdeaPic from './imgs/idea.png';
import Process from 'common/Process'

const CircleDivider = styled.div`
  width: 3px;
  height: 3px;
  margin-right: 5px;
  margin-left: 5px;
  border-radius: 50px;
  background-color: ${colors.textPrimary};
  display: none;
  @media only screen and (min-width: 321px) {
    display: block;
  }
`;
const Divider = styled.div`
  width: 70%;
  height: 1px;
  margin: 3rem 0 6rem 0;
  background-color: #979797;
`;

const Footer = styled.footer`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 6rem;
  color: ${colors.textPrimary} !important;
`;

const LinkGroup = styled.footer`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: ${colors.textPrimary} !important;
  @media only screen and (min-width: 321px) {
    flex-direction: row;
  }
`;
const TopImage = styled.img`
  width: 95%;
  max-width: 500px;
`;

const CopyRights = styled.div`
  color: ${colors.textSecondary}
`;

const Section2 = styled.div`
  background: url(${backgr}); 
  @media only screen and (min-width: 400px) {
    background-repeat: no-repeat;
    background-size: cover;
    background-position-y: 30%;
  }
  @media only screen and (min-width: 1400px) {
    background-position-y: 60%;
  }
  padding: 4rem 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-repeat: no-repeat;
  background-size: cover;
  background-position-y: 80%;
`;

const Section = styled.div`
  background-color: ${({color})=>color || 'white'};
  padding: ${({disableGutter})=>disableGutter?'0':'4rem 0 4rem 0'};
  display: flex;
  flex-direction: column;
  align-items: center;
  ${({gradient=''})=>gradient && 'background: radial-gradient(circle at bottom,#aad2e2 10%,#3066db 70%);'};
`;

function App() {
  React.useEffect(()=>{
    window.scrollTo(0,0);
  }, [])
  return (
    <div className="App">
      <NavBar>
        <Container>
          <div><Logo /></div>
          <HideOnMobile>
            <NavButton href="#work">Work</NavButton>
            <NavButton href="#about">About</NavButton>
            <NavButton href="#contacts">Contacts</NavButton>
          </HideOnMobile>
          <ShowOnMobile>
            <Menu />
          </ShowOnMobile>
        </Container>
      </NavBar>
      <Section2 color="#7B1EA7" gradient>
        <Header color="white" align="center">ImmiFriends App<br/>Case Study</Header>
        <TopImage src={TopLevelImg} alt="ImmiFriends App" />
      </Section2>
      <Section color="#7B1EA7">
        <Container direction="column">
          <Title align="center" color="white">ImmiFriends</Title>
          <Paragraph align="center" color="white">This project was done as a part of Design Lab studying program.
            It is tough to be an immigrant. It is even harder when you have no people around to support you.
            This project was created to help immigrants find new friends, events, groups and become a part of their native community.</Paragraph>
          <Title align="center" color="white">Goals</Title>
          <Paragraph align="center" color="white">Create an app where immigrants could find friends and news about their native community. Keep design simple, appealing and attentive to details. Dig deep into research to make an app user-centered.</Paragraph>
          <Title align="center" color="white">Users</Title>
          <Paragraph align="center" color="white">Immigrants from different countries living in different countries. Also, travellers can use an app too!</Paragraph>
        </Container>
      </Section>
      <Section style={{paddingBottom: 0}}>
        <Container direction="row" style={{flexWrap: 'wrap', marginBottom: '4rem'}}>
          <Process divider={5} title="research" pic={ResearchPic} items={['competitor analysis','interviews','persona development','empathy map']} />
          <Process divider={5} title="ideation" pic={IdeaPic} items={['problem statement','ideation']} />
          <Process divider={5} title="interaction design" pic={ProcessPic} items={['user flow','wireframes']} />
          <Process divider={5} title="user interface design" pic={WireframePic} items={['branding','UI design']} />
          <Process divider={5} showDivider={false} title="iteration and implementation" pic={ConversationPic} items={['usability testing','iteration']} />
        </Container>
        <Container direction="column">
          <Title align="center">Project summary</Title>
          <Paragraph align="center">
            Task was simple and hard at the same time, “end-to-end app to help you solve your biggest struggle”. Well, that is easy. My biggest struggle is finding friends in a new country I live in. That is how ImmiFriends app idea came to my mind. But what do other people think about that? Luckily, there is enough immigrants around me, so the research process was broad and thorough and made it possible to dig deep into the needs and struggles of users that could be solved through my app.
          </Paragraph>
          <Divider />
          <Title align="center">Research</Title>
          <Paragraph align="center">Research started with competitor analysis of apps that are already on the market. Peanut, Tinder and Bumble. The results you can see in the picture.</Paragraph>
          <img src={ResearchRampUp} alt="Persona" style={{width:'100%', marginTop: 10, marginBottom: 40}}/>
          <Paragraph align="center">Then, interviews were conducted. Interviewees were asked about how they manage to live in a new environment, what is their lifestyle, and finally, did they find friends or close people to talk to and how they did it.</Paragraph>
          <Paragraph align="center">7 interviews were taken. It was quickly discovered that some users prefer just to text others right away, but there were also interviewees who would spend time scrolling through people’s activity in different discussions or groups. Some prefer to go to an event and chat over there, but others would also go to an event just to … observe. Below you can see the summary of interviews using keywords technique.</Paragraph>
          <img src={Interview1} alt="Persona" style={{width:'100%', marginTop: 16}}/>
          <img src={Interview2} alt="Persona" style={{width:'100%', marginBottom: 40}}/>
          <Paragraph align="center">Using the Data received from interviews, persona that represents the main user group was created. Elena is a Software Engineer.  She moved to the USA from Russia two years ago. Elena is busy at work, so whenever she agrees to meet with someone for a coffee she wants to make sure it is worth it.</Paragraph>
          <img src={Persona} alt="Persona" style={{width:'100%', marginBottom: 40}}/>
          <Paragraph align="center">To understand Elena’s struggles and goals even better, Empathy map was created. You can see the results below.</Paragraph>
          <img src={ImmiFriendsEmpathyMap} alt="Persona" style={{width:'100%', marginTop: 10, marginBottom: 40}}/>
        </Container>
        <Container direction="column">
          <Title align="center">Research findings are:</Title>
          <Paragraph align="center">
            <ul style={{listStyle:'none', margin:0, padding:0}}>
              <li>- Users believe that similar interests are very important when connecting with someone</li>
              <li>- Users stated that they will scroll the profile of the person before texting directly</li>
              <li>- Users tend to read person’s posts to get a better understanding of a personality</li>
              <li>- Users prefer real communication, so events as the first place to meet works best</li>
              <li>- It is important to stay local as everyone is so busy now</li>
              <li>- Users stated that general information is important when trying to understand whether to text or not</li>
              <li>- Some Users stated that they use intuition to understand whether the person is a match or not</li>
              <li>- Users want others to show initiative and an effort connecting with each other</li>
            </ul>
          </Paragraph>
          <Divider/>
          <Title align="center">Ideation</Title>
          <Paragraph align="center">During research process main pain points were discovered. Also, with the help of "How might we ..." technique the main problem was stated.</Paragraph>
        </Container>
      </Section>
      <Section color="#7B1EA7">
        <Container direction="column">
          <Quote color="white" align="center">How might we help User to see the full picture of a person to make a decision of going further into communication.</Quote>
        </Container>
      </Section>
      <Section>
        <Container direction="column">
          <Paragraph align="center">My answer was this: We have to take a step back, and make sure our Users register in an app giving all important information. The more information they share, the better decision others can make to match ideally.</Paragraph>
          <Paragraph align="center">Crazy 8th technique helped to get rapid ideas to solve the main problem, and dig deeper and deeper finding new ways to design an interface that would make things easy for users. </Paragraph>
          <img src={Ideation} alt="Persona" style={{width:'100%', marginTop: 10, marginBottom: 40}}/>
          <Divider/>
        </Container>
        <Container direction="column">
          <Title align="center">Interaction Design</Title>
          <Paragraph align="center">User Flow was created for our persona Elena. Elena is going to register in ImmiFriends app.</Paragraph>
          <img src={UserFlow} alt="Persona" style={{width:'100%', marginTop: 10, marginBottom: 40}}/>
          <Paragraph align="center">The next step was to create Wireframes. Screens are simple, but have all the features that users asked for or might need during the process. Here is a screenshot from Sketch that shows all the Wireframes including grid.</Paragraph>
          <img src={Wireframes} alt="Persona" style={{width:'100%', marginTop: 10, marginBottom: 40}}/>
          <Paragraph align="center">When the Wireframes set was done, it was time to work on UI designs.</Paragraph>
          <Divider/>
          <Title align="center">User Interface Design</Title>
          <Paragraph align="center">I had to create a brand. My first step was to start sketching Logo. You can see first drafts and ideas in the picture below. Further, the whole process moved to Sketch for developing Brand Style Tile.</Paragraph>
          <div style={{display: 'flex', maxWidth: 800}}>
            <img src={Logo1} alt="Logo 1" style={{width:'50%'}}/>
            <img src={Logo2} alt="Logo 2" style={{width:'50%'}}/>
          </div>
          <img src={Branding} alt="Branding" style={{width:'100%', maxWidth: 800}}/>
          <Paragraph align="center" style={{margin: '4rem 0'}}>Below you can see final UI screens. Simple, easy to follow, bright but not overwhelming and not overloaded.</Paragraph>
          <img src={UIScreens} alt="UI screens" style={{width:'100%', maxWidth: 800, marginBottom: 40}}/>
          <Divider/>
          <Title align="center">Iteration and Implementation</Title>
          <Paragraph align="center">There was one round of Usability Testing that was conducted with designed screens using InVisionApp prototyping tool. Surprisingly, users did not give any specific feedback, there seemed to be no confusions or points where my interviewees were stuck.</Paragraph>
        </Container>
      </Section>
      <Section color="#7B1EA7">
        <Container direction="column">
          <Quote color="white" align="center">“ Everything looks so nice! I love colors! The process itself was smooth and predictable. ”</Quote>
        </Container>
      </Section>
      <Section>
        <Container direction="column">
          <Divider/>
          <Title align="center">Reflection</Title>
          <Paragraph align="center">This project was a great pleasure to work with from the very beginning. I loved the idea of creating something! The research part was huge, but it gave me even more experience talking to people. Keeping conversation going even though there were no specific questions, just listening to the stories and showing empathy so they felt safe to share. Using different techniques during my research definitely helped to look at the problems in a different angle.</Paragraph>
          <Paragraph align="center">The branding part was my personal favorite. Sometimes it was a struggled to find the “right” color, for example, but spending time playing with different options gave me the really exciting result. Creating a Logo was unexpectedly difficult while sketching, ideas that looked good on paper turned into nightmare once in Sketch. It was a challenge.</Paragraph>
          <Paragraph align="center">That was definitely my favorite project. And the most personal one! I am eager to do more of a research, problem statements and design in my career.</Paragraph>
        </Container>
      </Section>
      <Footer id="contacts">
        <LinkGroup>
          <ViewLink href="mailto:anastasiia.ezhikova@gmail.com?subject=Let's Connect!">anastasiia.ezhikova@gmail.com</ViewLink>
          <CircleDivider />
          <ViewLink rel="noopener noreferrer" target="_blank" href="https://www.linkedin.com/in/anastasiia-ezhikova-04538714a">LinkedIn</ViewLink>
        </LinkGroup>
        <CopyRights>© 2019 Anastasiia Ezhikova</CopyRights>
      </Footer>
    </div>
  );
}

export default App;
