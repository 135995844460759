import React from 'react'
import styled from 'styled-components'
import { breakpoints, colors } from 'theme'
import Container from 'common/components/Container'
import Paragraph from 'typography/Paragraph'
import { Link } from 'react-router-dom';

// const Card = styled.div`
const Card = styled.div`
  width: 100%
  display: flex;
  flex: inherit;
  justify-content: space-between;
  align-items: inherit;
  width: 100%;
  margin: auto;
  overflow: hidden;
  flex-direction: column;
  box-shadow: 0px 2px 4px -1px rgba(0,0,0,0.2), 0px 4px 5px 0px rgba(0,0,0,0.14), 0px 1px 10px 0px rgba(0,0,0,0.12);
  margin-top: 4rem;
  margin-bottom: 2rem;
  @media only screen and (min-width: ${breakpoints.minWidthTablet}px) {
    max-width: 95%;
    flex-direction: row;
    border-radius: 0.8rem;
  }
  @media only screen and (min-width: ${breakpoints.minWidthLaptop}px) {
    max-width: 90%;
  }
  @media only screen and (min-width: ${breakpoints.minWidthDesktop + 1}px) {
    max-width: ${breakpoints.minWidthDesktop}px;
  }
`

const StyledLink = styled(Link)`
  padding: 50%;
  position: relative;
  @media only screen and (min-width: ${breakpoints.minWidthTablet}px) {
    padding: 20%;
  }
`

const LinkImage = styled.div`
  background: url(${({img}) => img});
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
`

const Title = styled.h3`
  color: ${({color})=>colors[color] || colors.textPrimary}
  margin: 4rem 0;
  font-size: 2.2rem;
  font-weight: 500;
  line-height: 1;
  ${({align})=>align?`text-align: ${align};`:''}
`;

const Description = styled.div`
  width: 90%;
  padding-bottom: 2rem;
  @media only screen and (min-width: ${breakpoints.minWidthTablet}px) {
    width: 55%;
    padding-right: 2rem;
  }
`;

export const ViewLink = styled(Link)`
  color: ${colors.primary};
  font-size: 1.6rem;
  line-height: 2.3rem;
  letter-spacing: 0.05rem;
  :hover {
    text-decoration: underline;
  }
`;

export default ({img, title, children, link = '/'}) => (
  <Card>
    <StyledLink to={link}><LinkImage img={img}/></StyledLink>
    <Description>
      <Title>
        {title}
      </Title>
      <Paragraph>
      {children}
      </Paragraph>
      <ViewLink to={link}>View case study</ViewLink>
    </Description>
  </Card>
);