import React from 'react'
import styled from 'styled-components';
import MenuIcon from '@material-ui/icons/Menu';
import IconButton from '@material-ui/core/IconButton'
import SwipeableDrawer from '@material-ui/core/SwipeableDrawer'
import NavButton from 'navigation/components/NavButton'
import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import ListItemText from '@material-ui/core/ListItemText'
import { colors } from 'theme'


const MenuButton = styled(IconButton)`
  &&{
    width: 6rem;
    height: 6rem;
  }
`;

const MenuButtonIcon = styled(MenuIcon)`
  &&{
    width: 3.4rem;
    height: 3.4rem;
  }
`;

const Flex = styled.div`
  && {
    justify-content: space-between;
  }
`;

const Text = styled.span`
  color: ${colors.textPrimary};
  font-size: 1.5rem;
  line-height: 2rem;
  font-weight: 500;
  text-align: center;
  letter-spacing: 0.1rem;
  cursor: pointer;
  text-transform: uppercase;
  margin: 0;
  padding: 0 0.8rem 0.5rem;
  :hover {
    color: ${colors.primary}
    border-bottom: 3px solid ${colors.primary};
  }
`;

export default function Menu() {
  const [open, setOpen] = React.useState(false);
  return (
    <React.Fragment>
      <MenuButton color="inherit" aria-label="menu" onClick={()=>setOpen(true)}>
        <MenuButtonIcon />
      </MenuButton>

      <SwipeableDrawer
        PaperProps={{component: Flex}}
        open={open}
        onClose={()=>setOpen(false)}
        onOpen={()=>setOpen(true)}
      >
        <List>
            <ListItem button component="a" href="#work" onClick={()=>setOpen(false)}>
              <ListItemText disableTypography style={{textAlign:'center'}}>
                <Text>Work</Text>
              </ListItemText>
            </ListItem>
            <ListItem button component="a" href="#about" onClick={()=>setOpen(false)}>
              <ListItemText disableTypography style={{textAlign:'center'}}>
                <Text>About</Text>
              </ListItemText>
            </ListItem>
            <ListItem button component="a" href="#contacts" onClick={()=>setOpen(false)}>
              <ListItemText disableTypography style={{textAlign:'center'}}>
                <Text>Contacts</Text>
              </ListItemText>
            </ListItem>
        </List>
        <div style={{width:'100vw'}}>
          <List>
            <ListItem button onClick={()=>setOpen(false)}>
              <ListItemText disableTypography style={{textAlign:'center'}}>
                <Text>Close</Text>
              </ListItemText>
            </ListItem>
          </List>
        </div>
      </SwipeableDrawer>
    </React.Fragment>
  );
}
