import styled from 'styled-components'
import {colors} from 'theme'

export default styled.span`
  color: ${({color})=>colors[color] || colors.textPrimary}
  font-weight: 500;
  line-height: 1;
  font-size: 1.8rem;
  text-align: center;
  letter-spacing: 0.225rem;
  text-transform: uppercase;
  width: 100%
`;
