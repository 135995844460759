import styled from 'styled-components';
import {breakpoints} from 'theme'

export default styled.div`
  width: 100%
  display: flex;
  flex: inherit;
  justify-content: inherit;
  align-items: inherit;
  width: 100%;
  padding: 0 1rem;
  margin: auto;
  ${({direction})=>direction?`flex-direction: ${direction};`:''}
  @media only screen and (min-width: ${breakpoints.minWidthTablet}px) {
    max-width: 95%;
  padding: 0;
  }
  @media only screen and (min-width: ${breakpoints.minWidthLaptop}px) {
    max-width: 90%;
  }
  @media only screen and (min-width: ${breakpoints.minWidthDesktop}px) {
    max-width: ${breakpoints.minWidthDesktop}px;
  }
  @media only screen and (min-width: ${breakpoints.minWidthDesktopXl+100}px) {
    max-width: ${breakpoints.minWidthDesktopXl}px;
  }
`;