import React from 'react';
import styled from 'styled-components'
import { breakpoints, colors } from 'theme'

export default styled.h3`
  font-family: 'Montserrat', sans-serif;
  color: ${({color})=>colors[color] || colors.textPrimary}
  margin: 1rem 0 1.5rem 0;
  font-size: 2.6rem;
  font-weight: 600;
  line-height: 3.2rem;
  ${({align})=>align?`text-align: ${align};`:''}
  margin-top: 2rem;
  @media only screen and (min-width: ${breakpoints.minWidthTablet}px) {
    margin-top: 0;
  }
`;
