import React from 'react';
import styled from 'styled-components'
import {colors} from 'theme'

const NavButton = styled.a`
  color: ${colors.textPrimary};
  font-size: 1.5rem;
  line-height: 2rem;
  font-weight: 500;
  text-align: center;
  letter-spacing: 0.1rem;
  cursor: pointer;
  text-transform: uppercase;
  margin: 0;
  padding: 0 0.8rem 0.5rem;
  :hover {
    color: ${colors.primary}
    border-bottom: 3px solid ${colors.primary};
  }
`;

export default NavButton;